import React from 'react';
import { createRoot } from 'react-dom/client';
import { Steps } from 'antd';
// import 'antd/dist/antd.css';

const StepsComponent = ({ steps }) => (
  <Steps direction="vertical" current={1} items={steps} />
);

const renderStepsComponentInElement = async (el) => {
  const steps = JSON.parse(el.getAttribute('data-steps'));
  const root = createRoot(el);
  root.render(<StepsComponent steps={steps} />);
};

const renderStepsComponents = () => {
  const stepsElements = document.querySelectorAll('.steps');
  stepsElements.forEach(renderStepsComponentInElement);
};

document.addEventListener('DOMContentLoaded', renderStepsComponents);
